module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://valdymas.medeinos.lt","contentApiKey":"989cdabb62ea66df909951bf04"},"production":{"apiUrl":"https://valdymas.medeinos.lt","contentApiKey":"989cdabb62ea66df909951bf04"}},"siteConfig":{"siteUrl":"https://www.medeinos.lt","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Augalai, aplinka ir aplinkosauga - Medeinos tinklaraštis","siteDescriptionMeta":"Medeinos tinklaraštyje aptariami sumedėję augalai, miškai, ekologija ir aplinka apskritai, tiek sukurta Gamtos, tiek tausojančiai kuriama žmogaus.","shortTitle":"Medeinos","siteIcon":"favicon.png","backgroundColor":"#8cc864","themeColor":"#372314","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":false,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[{"path":"/augalu-katalogas/a/"},{"path":"/augalu-katalogas/b/"},{"path":"/augalu-katalogas/c/"},{"path":"/augalu-katalogas/d/"},{"path":"/augalu-katalogas/e/"},{"path":"/augalu-katalogas/f/"},{"path":"/augalu-katalogas/g/"},{"path":"/augalu-katalogas/h/"},{"path":"/augalu-katalogas/i/"},{"path":"/augalu-katalogas/y/"},{"path":"/augalu-katalogas/j/"},{"path":"/augalu-katalogas/k/"},{"path":"/augalu-katalogas/l/"},{"path":"/augalu-katalogas/m/"},{"path":"/augalu-katalogas/n/"},{"path":"/augalu-katalogas/o/"},{"path":"/augalu-katalogas/p/"},{"path":"/augalu-katalogas/r/"},{"path":"/augalu-katalogas/s/"},{"path":"/augalu-katalogas/t/"},{"path":"/augalu-katalogas/u/"},{"path":"/augalu-katalogas/v/"},{"path":"/augalu-katalogas/z/"},{"path":"/augalu-katalogas/"}]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[],"maxDepth":2},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-56114318-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"medeinos.lt","exclude":[]},
    }]
